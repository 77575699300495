import React, { Component } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import GalleryImage from "../components/GalleryImage"

import BlogVideo from "../components/BlogVideo"

const imagesPerPage = 12
const attrMap = {
  "single-image": "image",
  "wide-image": "image",
  video: "file",
}

const GridImageLink = styled(Link)``

const Container = styled.div`
  display: block;
`

const GalleryList = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(11rem, 100%), 1fr));
  grid-template-rows: repeat(auto-fill, 140px);
  grid-gap: 1rem;
  grid-auto-flow: dense;
  grid-auto-rows: min-content;
  /* popover labels */
  li {
    overflow: hidden;
    margin: 0px;
  }

  li > a > div {
    height: 100%;
    margin: auto;
  }

  li:nth-child(4n) {
    grid-column: span 2;
    grid-row: span 2;
  }
  li:nth-child(8n) {
    grid-column: span 3;
    grid-row: span 3;
  }
  li > a > figure > img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }

  li:hover {
    div.image_overlay {
      transform: translateY(-140%);
    }
  }
`

// const ImageOverlay = styled.div`
//   background: #ffffff32;
//   position: relative;
//   display: grid;
//   justify-items: center;
//   align-items: center;
//   // transform: translateY(100%);
//   transition: 0.2s;
//   button {
//     background: none;
//     border: 2px solid white;
//     color: white;
//     text-transform: uppercase;
//     background: rgba(0, 0, 0, 0.7);
//     padding: 10px;
//   }
//   span {
//     color: whitesmoke;
//   }
// `

const LoadMoreButton = styled.button`
  width: 100%;
`

const constructImageObj = (image, edge) => ({
  [image]: edge.node.frontmatter.slug,
  date: edge.node.frontmatter.posted_at,
  title: edge.node.frontmatter.title,
})

const extractThumbnail = edge =>
  edge.displaythumbnailingallery
    ? constructImageObj(edge.node.frontmatter.thumbnail)
    : null

const extractPostImages = edge =>
  edge.node.frontmatter.block.reduce(
    (acc, block) =>
      Object.keys(attrMap).includes(block.template) && block.showingallery
        ? [...acc, constructImageObj(block[attrMap[block.template]], edge)]
        : acc,
    []
  )

const extractToggleVideos = edge =>
  edge.node.frontmatter.block
    .filter(block => block.template === "togglevideo")
    .reduce(
      (acc, block) => [
        ...acc,
        constructImageObj(block.video_a, edge),
        constructImageObj(block.video_b, edge),
      ],
      []
    )

const extractImages = edges => {
  const fullList = edges.reduce(
    (tally, edge) =>
      [
        ...tally,
        extractThumbnail(edge),
        ...extractPostImages(edge),
        ...extractToggleVideos(edge),
      ].filter(Boolean),
    []
  )
  return fullList
}

class LazyImageLoader extends Component {
  constructor(props) {
    super(props)
    this.fullList = extractImages(props.data.allMarkdownRemark.edges)
    this.state = {
      renderedImages: this.fullList.slice(0, imagesPerPage),
      unrenderedImages: this.fullList.slice(imagesPerPage),
    }
  }

  nextPage = () => {
    this.setState({
      renderedImages: [
        ...this.state.renderedImages,
        ...this.state.unrenderedImages.slice(0, imagesPerPage),
      ],
      unrenderedImages: [...this.state.unrenderedImages.slice(imagesPerPage)],
    })
  }

  //   state = {
  //     imageList: [],
  //     nextPage: 1,
  //   }
  render() {
    return (
      <Container>
        <GalleryList>
          {this.state.renderedImages
            .map(Object.entries)
            .map(
              (
                [[url, slug], [dateLabel, date], [titleLabel, title]],
                index
              ) => {
                const isVideo = url.substr(url.length - 3) === "mp4"
                if (isVideo) {
                  return (
                    <li key={index}>
                      <GridImageLink to={`/blog/${slug}`}>
                        <BlogVideo
                          src={url}
                          controls={false}
                          loop={true}
                          autoPlay={true}
                          muted={true}
                          playsInline={true}
                          optimize={true}
                        />
                      </GridImageLink>
                    </li>
                  )
                }
                return (
                  <li key={index}>
                    <GridImageLink to={`/blog/${slug}`}>
                      <GalleryImage src={url} />
                    </GridImageLink>
                  </li>
                )
              }
            )}
        </GalleryList>
        <LoadMoreButton onClick={this.nextPage}>Load More</LoadMoreButton>
      </Container>
    )
  }
}

export default LazyImageLoader
