import React from "react"
import Img from "gatsby-image"

import { StaticQuery, graphql } from "gatsby"

function getFilenameFromPath(path) {
  const pieces = path.split("/")
  return pieces[pieces.length - 1]
}

function renderImage(file, alt) {
  return <Img fluid={file.node.childImageSharp.fluid} alt={alt} />
  // return (
  //   <figure>
  //     <img src={file.node.childImageSharp.fluid.src} />
  //   </figure>
  // )
}
const GalleryImage = function(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }
          ) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={({ images }) => {
        return renderImage(
          images.edges.find(
            image => image.node.relativePath === getFilenameFromPath(props.src)
          ),
          props.alt
        )
      }}
    />
  )
}
export default GalleryImage
