import React from "react"
import { graphql } from "gatsby"


import SEO from "../components/seo"
import LazyImageLoader from "../components/LazyImageLoader"

const Gallery = ({ data }) => (
  <>
    <SEO title="Gallery" />
    <LazyImageLoader data={data}/>
  </>
)

export default Gallery


export const query = graphql`
  query GalleryQuery {
    allMarkdownRemark(
      filter: { fields: { sourceInstanceName: { eq: "markdown-pages" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            thumbnail
            displaythumbnailingallery
            posted_at(formatString: "MMM D, YYYY")
            slug
            category
            block {
              template
              paragraph
              image
              alt
              text
              style
              slug
              content
              file
              showingallery
              video_a
              video_b
            }
          }
        }
      }
    }
  }
`